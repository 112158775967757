import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./valuation.scss"
import { Valuation_Page_Url } from "@Components/common/site/constants"
import { Link } from "gatsby"
const Valuation = () => {
  return (
    <section className="valuation w-100 mb-5 marketing_property">
      <Row>
        <Col>
          <div className="valuation-banner property-list">
            <div className="valuation-details">
              <i className="icon-home"></i>
              <h3>
                start <span className="highlight">marketing your property</span>{" "}
                with dng
              </h3>
              <p>Book your property valuation today with one of our experts.</p>
            </div>
            <div className="valuation-cta">
              <Link to={Valuation_Page_Url.alias+"/"} className="btn">
                book valuation
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Valuation
