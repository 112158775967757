import React, { useEffect, useState } from "react";
import axios from "axios"
import $ from "jquery"
import { toTitleCase, commercialFilters, lettingsFilters } from "../../property-search/utils"
import _ from "lodash"

const KeywordSearch = (props) => {
    var array_merge = {commercialSpecial:[], lettingsSpecial:[]}
    const [must_includes, setMustIncludes] = useState([])
    const [input_value, setInputValue] = useState("")

    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {})
            setMustIncludes(_.omit(data, ['style']))
        } catch (error) {
            console.error(error)
        }
    }

    const handleCheck = (e) => {
        //console.log("checked", e.target.checked)
        
        if(e.target.checked){
            props.setMustIncludeArr([...props.mustInclude_Arr, e.target.value])
        } else{
            // var mustincl_arr = props.mustInclude_Arr;
            // var index = mustincl_arr.indexOf(e.target.value); // Let's say it's Bob.
            // if (index !== -1) {
            //     mustincl_arr.splice(index, 1);
            //     props.setMustIncludeArr(mustincl_arr)
            // }

            var removeItem = props.mustInclude_Arr.filter(item => item !== e.target.value)
            props.setMustIncludeArr(removeItem)
        }

        $(".keyword_section").removeClass("d-none")
    }

    useEffect(()=>{
        let url = `${process.env.GATSBY_STRAPI_SRC}/refined_keywords.json`
        getitems(url);

        $(".find-property-wrapper").on('click', "div, input, ul, li", function(){
           
            var form_control_cls = false;
            if($(this).attr('class') === "form-control"){
                form_control_cls = true;
                $(".keyword_section").removeClass("d-none")
                return false;
            } if($(this).attr('class') === "keyword_section"){
                form_control_cls = true;
                $(".keyword_section").removeClass("d-none")
                return false;
            } else{
                $(".keyword_section").addClass("d-none")
            }
           // console.log("form_control_cls", form_control_cls)
           
            //$(".keyword_section").removeClass("d-none")
        });

        // $(".find-property-wrapper").on('click', function(){
        //     $(".keyword_section").addClass("d-none")
        // })

    },[])

   
    if (props.department === "commercial") {
      commercialFilters.map(value => {
        if (value) {
          array_merge.commercialSpecial.push(value)
        }
      })
    } else {
      lettingsFilters.map(value => {
        if (value) {
          array_merge.lettingsSpecial.push(value)
        }
      })
    }
    var must_Includes = { ...array_merge, ...must_includes }

    var must_include_object =  Object.keys(must_Includes).filter(item => item !== "special");
    if(props.department === "commercial"){
        must_include_object = Object.keys(must_Includes).filter(item => item !== "res_special");
    }

    const removeType = val => {
      var res =
        val !== "type" &&
        val !== "furnishing" &&
        val !== "special" &&
        val !== "res_special"
      return res
    }
    must_include_object = must_include_object?.filter(removeType)

   //console.log("must_include_object ==>",  must_include_object);

    return (
        <>
            <div className="col-12">
                <label className="mb-3">Select keywords to refine your search </label>
                <div className={`form-content ${props.tag === "auction" ? "mb-4" : ""}`}>
                    <div className="input-wrapper keyword-search">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="ex: garden, attic"
                            value={(props?.mustInclude_Arr?.join(", "))?.replace(/-/g,' ')}
                            onChange={(e) => setInputValue(e.target.value)}
                            //onFocus={()=>props.setFocusValue(true)}
                            readOnly

                        />
                            {/* {props.focus_cls && <span className="dropdown_close" onClick={()=>props.setFocusValue(false)}></span>} */}

                       
                            {must_include_object.length > 0 &&
                                <div className={`keyword_section d-none`} onClick={() =>props.setFocusValue(true) }>

                                    
                                    { must_include_object?.map((item, index) => {

                                        var must_include_items =  must_Includes[item];

                                        var heading = (item === "commercialSpecial" || item === "lettingsSpecial") ? "special" : item;
                                        
                                        if(input_value !== ""){
                                            var find_items =  must_Includes[item].filter((f_item) => (f_item.name.toLowerCase()).includes(input_value));

                                            //console.log("find_items", input_value, find_items);

                                            
                                            
                                            if(find_items.length > 0){
                                                return(
                                                    <>
                                                        <p className="filter-check-boxes">{toTitleCase(heading)}</p>
                                                        <ul>
                                                            {find_items.map(minc_item => {

                                                                var checked_val = props.mustInclude_Arr.filter(value => value === minc_item.slug)
                                                                return(
                                                                    
                                                                    <li>
                                                                        <input type="checkbox" name="srch_kwd" value={minc_item.slug} onChange={handleCheck} checked={checked_val.length > 0 ? true : false} /> {minc_item.name}
                                                                    </li>
                                                                )}
                                                               )
                                                            }                                                        
                                                        </ul>
                                                    </>
                                                )
                                            }
                                            
                                        } else{
                                            if(must_include_items.length > 0){
                                                return(
                                                    <>
                                                        <p className="filter-check-boxes">{toTitleCase(heading)}</p>
                                                        <ul>
                                                            {must_include_items.map(minc_item =>  {
                                                                var checked_val = props.mustInclude_Arr.filter(value => value === minc_item.slug)

                                                                return(
                                                                    <li><input className="check_box_val" type="checkbox" name="srch_kwd" value={minc_item.slug} onChange={handleCheck} checked={checked_val.length > 0 ? true : false} /> 
                                                                        <label>{minc_item.name}</label>
                                                                        <span className="checkmark"></span>
                                                                    </li>
                 
                                                                )}
                                                                
                                                            )}
                                                        
                                                        </ul>
                                                    </>
                                                )
                                            }
                                        }                                        
                                        
                                    })}
                                </div>
                            }                            
                    </div>

                    {/* <div className="btn-wrapper form-btns">
                        <div className="btn-wrap">
                        <button
                            className="btn"
                            onClick={props.keywordUpdate}
                        >
                            Update
                        </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default KeywordSearch;